var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "space-between" } },
        [
          _c(
            "v-col",
            { attrs: { sm: "4", md: "4" } },
            [
              _vm.showViewAsCustomer
                ? _c("ViewAsCustomer", { staticClass: "mt-5 ml-2" })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Admin" } } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2 mt-6",
                      attrs: { color: "success", dark: "" }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("\n            mdi-home-outline\n          ")
                      ]),
                      _vm._v("Admin Home\n        ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "ml-2" },
                [
                  _c("v-card-title", [
                    _vm._v("\n          Auto-Process Orders\n        ")
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "4", sm: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.downloadSelection,
                          "return-object": "",
                          dense: "",
                          outlined: "",
                          label: "Order Number",
                          color: "primary"
                        },
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "space-between" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { sm: "5", md: "5" } },
                        [
                          _c("v-card-title", { staticClass: "ml-2" }, [
                            _vm._v(
                              "\n              Search Results\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pr-6", attrs: { cols: "3", sm: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: "Search",
                              "single-line": "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    staticClass: "mt-10",
                    attrs: {
                      "item-key": "fileType",
                      headers: _vm.autoProcessHeaders,
                      items: _vm.orders,
                      search: _vm.search,
                      loading: _vm.resultsLoading,
                      "single-select": "",
                      "show-select": "",
                      "checkbox-color": "blue"
                    },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }