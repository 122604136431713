<template>
  <v-container>
    <v-row
      no-gutters
      justify="space-between"
    >
      <v-col
        sm="4"
        md="4"
      >
        <ViewAsCustomer
          v-if="showViewAsCustomer"
          class="mt-5 ml-2"
        />
      </v-col>
      <v-col
        cols="3"
      >
        <router-link :to="{ name: 'Admin' }">
          <v-btn
            class=" ma-2 mt-6"
            color="success"
            dark
          >
            <v-icon
              left
            >
              mdi-home-outline
            </v-icon>Admin Home
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-card-title>
            Auto-Process Orders
          </v-card-title>
          <v-col
            cols="4"
            sm="4"
          >
            <v-select
              v-model="selected"
              :items="downloadSelection"
              return-object
              dense
              outlined
              label="Order Number"
              color="primary"
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-row
            no-gutters
            justify="space-between"
          >
            <v-col
              sm="5"
              md="5"
            >
              <v-card-title class="ml-2">
                Search Results
              </v-card-title>
            </v-col>
            <v-col
              cols="3"
              class="pr-6"
              sm="3"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            item-key="fileType"
            class="mt-10"
            :headers="autoProcessHeaders"
            :items="orders"
            :search="search"
            :loading="resultsLoading"
            single-select
            show-select
            checkbox-color="blue"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AutoProcessOrder',
  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer')
  },
  data () {
    return {
      search: '',
      selected: '',
      resultsLoading: false,
      orders: [],
      autoProcessHeaders: [
        {
          text: 'File Type',
          align: 'start',
          value: 'fileType'
        },
        { text: 'Job Number', value: 'jobNumber', sortable: false },
        { text: 'Quantity', value: 'quantity', sortable: false, align: 'center' },
        { text: 'Download', value: 'download', sortable: false, align: 'center' },
        { text: 'Auto Process Allowed', value: 'allowed', sortable: false, align: 'center' },
        { text: 'Order Processed', value: 'processed', sortable: false, align: 'center' }
      ]
    }
  },
  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    }
  }
}
</script>
